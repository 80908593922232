import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { auth } from './firebaseConfig'; // Ensure you import Firebase auth
import { onAuthStateChanged } from 'firebase/auth';
import { useLogout } from './utils/logout';
import JoiAppLogo from './joiapplogo.png'; 
import './css/CalculatingPage.css';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const CalculatingPage = () => {
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [result, setResult] = useState(null);
    const logout = useLogout();

    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid);
            } else {
                console.error("User not authenticated");
                navigate('/');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            if (!userId) return;

            try {
                const API_URL = "http://localhost:8080";
           
          const response = await fetch(  'https://api.joiapp.org/analyze_mood_anxiety', {
        //        const response = await fetch('${API_URL}/analyze_mood_anxiety', {
          //  const response = await fetch('  https://joiappbackend-56278236485.us-central1.run.app/analyze_mood_anxiety', {  
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId }), // Pass userId to the Flask server
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log("data: ", data);
                    setResult(data);
                } else {
                    console.error('Calculation failed', await response.text());
                }
            } catch (error) {
                console.error('Error calculating predictions:', error);
            }
        };

        const interval = setInterval(() => {
            setProgress(prev => (prev < 100 ? prev + 10 : 100));
        }, 500); // Update progress every 500ms

        fetchData().finally(() => clearInterval(interval));

        return () => clearInterval(interval);
    }, [userId, navigate]);

    const renderChart = (predictions, actualScores, title) => {
        const chartData = {
            labels: Array.from({ length: predictions.length }, (_, i) => `Day ${i + 1}`),
            datasets: [
                {
                    label: `${title} Predictions`,
                    data: predictions,
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                },
                {
                    label: `Actual ${title} Scores`,
                    data: actualScores,
                    fill: false,
                    backgroundColor: 'rgba(255,99,132,0.4)',
                    borderColor: 'rgba(255,99,132,1)',
                }
            ],
        };

        const options = {
            responsive: true,
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Day',
                    },
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Score',
                    },
                },
            },
        };

        return <Line data={chartData} options={options} />;
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <h1>Calculating Predictions...</h1>
            <progress value={progress} max="100"></progress>
            {result && (
                <div>
                    <h2>Calculation Results</h2>
                    <div style={{ marginBottom: '20px' }}>
                        <h3>PHQ2 Predictions</h3>
                        {renderChart(result.phq2_prediction, result.actual_phq2_scores, 'PHQ2')}
                        <p>Current PHQ2 Score: {result.current_phq2}</p>
                        <p>PHQ2 Deviance: {result.phq2_deviance}</p>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <h3>GAD2 Predictions</h3>
                        {renderChart(result.gad2_prediction, result.actual_gad2_scores, 'GAD2')}
                        <p>Current GAD2 Score: {result.current_gad2}</p>
                        <p>GAD2 Deviance: {result.gad2_deviance}</p>
                    </div>
                    <p>Mood Analysis: {result.mood_analysis}</p>
                    <p>Anxiety Analysis: {result.anxiety_analysis}</p>
                </div>
            )}
            <button onClick={() => navigate('/results')}>Back to Results</button>
            <button onClick={logout} style={{ marginTop: '20px', height: '30px', alignSelf: 'center' }}>Logout</button>
        </div>
    );
};

export default CalculatingPage;
